<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="话题名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入话题名称" />
      </a-form-model-item>
      <a-form-model-item label="图片" prop="img">
        <a-upload
          name="img"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="true"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverAvatarUpload">
          <img
            v-if="form.img"
            :src="form.img"
            alt="img"
            style="height: 102px; width: 102px; border-radius: 50%"
          />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="标签" prop="label">
        <a-select style="width: 250px" v-model="form.label" placeholder="请输入标签">
          <a-select-option v-for="(item, index) in this.customDict.TopicTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否默认话题" prop="isDefault">
        <a-switch v-model="form.isDefault" />
        {{ form.isDefault ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="是否推荐" prop="isRecommend">
        <a-switch v-model="form.isRecommend" />
        {{ form.isRecommend ? '是' : '否' }}
      </a-form-model-item>
      <!-- 加: 代表取的是变量, 是值
       不加 ：代表取的是字符串-->
      <a-form-model-item label="是否下架" prop="isOff">
        <a-switch v-model="form.isOff" />
        {{ form.isOff ? '是' : '否' }}
      </a-form-model-item>

      <a-form-model-item label="热度值" prop="hotValue">
        <a-input v-model="form.hotValue" placeholder="请输入热度值" />
      </a-form-model-item>
      <!--      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
              <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0"/>
            </a-form-model-item>-->
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <!--      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
              <a-input v-model="form.version" placeholder="请输入乐观锁"/>
            </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTopic, addTopic, updateTopic } from '@/api/community/topic'
import { generateFilePath, uploadObject } from '@/api/tool/alioss'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      pictureUrl: '',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      uploading: false,
      // 表单参数
      form: {
        id: null,
        name: null,
        img: null,
        label: null,
        isDefault: null,
        isRecommend: null,
        isOff: null,
        hotValue: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '话题名称不能为空', trigger: 'blur' }
        ],

        label: [
          { required: true, message: '标签不能为空', trigger: 'blur' }
        ],

        isDefault: [
          { required: true, message: '是否系统默认 1:是 0:否不能为空', trigger: 'blur' }
        ],

        isRecommend: [
          { required: true, message: '是否推荐 1:是 0:否不能为空', trigger: 'blur' }
        ],

        isOff: [
          { required: true, message: '是否下架 1:是 0:否不能为空', trigger: 'blur' }
        ],

        hotValue: [
          { required: true, message: '热度值不能为空', trigger: 'blur' }
        ],

        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],

        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: { ...mapGetters(['customDict']) },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        img: null,
        label: null,
        isDefault: null,
        isRecommend: null,
        isOff: null,
        hotValue: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    beforeUpload(file) {
      const reader = new FileReader()
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.options.img = reader.result
      }
      // 转化为blob
      // reader.readAsArrayBuffer(file)

      return false
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTopic(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTopic(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            //this.form.isRecommend = parseInt(this.form.isRecommend)
            addTopic(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    //oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    coverAvatarUpload(file, index) {
      console.log('获取文件对象', file.file)
      let _this = this
      // 获取文件对象
      let uploadFile = file.file
      //文件夹目录
      let prefix = 'communityTopic'
      let url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'img', res)
          _this.$message.success('上传成功')
        })
      })
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      let suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      let filePath = generateFilePath(prefix, suffix)
      return filePath
    }
    //oss 上传结束
  }
}
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>